import { getQueryParam } from "../../src/utils/uri";

export function onClientEntry() {
  const ref = getQueryParam("ref");
  const refContext = getQueryParam("ref_context");

  if (ref) {
    sessionStorage.setItem("refId", ref);
  }

  if (refContext) {
    sessionStorage.setItem("refContext", refContext);
  }
}
