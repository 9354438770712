import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import theme from "../../src/utils/theme";

const TopLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};


export default TopLayout;
