import queryString from "query-string";

export const createSlugFromText = (text) =>
  encodeURIComponent(
    text
      .toLowerCase()
      .split(" ")
      .map((c) => c.replace(/[^a-zA-Z0-9]/g, ""))
      .join("-")
  );

/**
 * Get a query parameter from location search.
 * @param {string} param The query parameter to get from location.search
 */
export const getQueryParam = (param) => {
  try {
    const query = queryString.parse(window.location.search);
    return query[param] || null;
  } catch {
    return null;
  }
};
