exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-an-introducer-js": () => import("./../../../src/pages/become-an-introducer.js" /* webpackChunkName: "component---src-pages-become-an-introducer-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-natwest-business-js": () => import("./../../../src/pages/natwest-business.js" /* webpackChunkName: "component---src-pages-natwest-business-js" */),
  "component---src-pages-providers-js": () => import("./../../../src/pages/providers.js" /* webpackChunkName: "component---src-pages-providers-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-provider-js": () => import("./../../../src/templates/Provider.js" /* webpackChunkName: "component---src-templates-provider-js" */),
  "component---src-templates-service-category-js": () => import("./../../../src/templates/ServiceCategory.js" /* webpackChunkName: "component---src-templates-service-category-js" */),
  "component---src-templates-service-embed-js": () => import("./../../../src/templates/ServiceEmbed.js" /* webpackChunkName: "component---src-templates-service-embed-js" */),
  "component---src-templates-service-enquiry-js": () => import("./../../../src/templates/ServiceEnquiry.js" /* webpackChunkName: "component---src-templates-service-enquiry-js" */),
  "component---src-templates-service-enquiry-protection-js": () => import("./../../../src/templates/ServiceEnquiryProtection.js" /* webpackChunkName: "component---src-templates-service-enquiry-protection-js" */),
  "component---src-templates-service-enquiry-stepped-js": () => import("./../../../src/templates/ServiceEnquiryStepped.js" /* webpackChunkName: "component---src-templates-service-enquiry-stepped-js" */),
  "component---src-templates-service-overview-executive-income-js": () => import("./../../../src/templates/ServiceOverviewExecutiveIncome.js" /* webpackChunkName: "component---src-templates-service-overview-executive-income-js" */),
  "component---src-templates-service-overview-js": () => import("./../../../src/templates/ServiceOverview.js" /* webpackChunkName: "component---src-templates-service-overview-js" */),
  "component---src-templates-service-overview-keyman-js": () => import("./../../../src/templates/ServiceOverviewKeyman.js" /* webpackChunkName: "component---src-templates-service-overview-keyman-js" */),
  "component---src-templates-service-overview-relevant-life-js": () => import("./../../../src/templates/ServiceOverviewRelevantLife.js" /* webpackChunkName: "component---src-templates-service-overview-relevant-life-js" */),
  "component---src-templates-simple-text-page-js": () => import("./../../../src/templates/SimpleTextPage.js" /* webpackChunkName: "component---src-templates-simple-text-page-js" */)
}

