import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    misc: {
      reviewGreen: "#4caf50",
    },
    primary: {
      main: "#135e9c",
    },
    secondary: {
      main: "#0097a7",
    },
    neutral: {
      main: "#e0e0e0",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    text: {
      primary: "rgba(10, 31, 53, 0.87)",
      secondary: "rgba(10, 31, 53, 0.54)",
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    body1: {
      fontSize: "1.125rem",
    },
    h1: {
      fontWeight: 700,
      fontSize: "4rem",
    },
    h2: {
      fontWeight: 400,
      fontSize: "3.25rem",
    },
    h3: {
      fontWeight: 400,
      fontSize: "2.75rem",
    },
    h4: {
      fontWeight: 400,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 400,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 400,
    },
  },
});

export default theme;
